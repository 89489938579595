import React, {useState, useEffect} from 'react';
import { Redirect } from "react-router-dom";
import { useDispatch } from 'react-redux';

import Api from './components/Api'
/* import { setLoading } from './components/Utils' */

const Slug = ({match}) => {
  const {tenant} = match.params;

  const dispatch = useDispatch();

  const [status, setStatus] = useState(0);

  const setStore = (data) => {
    /* console.log(data); */
    dispatch({
      type: 'SET_TENANT_CONFIG',
      payload: { configs: data.configs }
    });
    dispatch({
      type: 'SET_TENANT_ENDERECOS',
      payload: { enderecos: data.enderecos } // JSON.stringify(resp.data.enderecos)
    });
    dispatch({
      type: 'SET_TENANT_TELEFONES',
      payload: { telefones: data.telefones } // JSON.stringify(resp.data.telefones)
    });

    delete data.enderecos;
    delete data.telefones;
    delete data.configs;

    dispatch({
      type: 'SET_TENANT',
      payload: { tenant: data } // JSON.stringify(resp.data.telefones)
    });


    /* dispatch({
      type: 'SET_WAWELCOMETANDALONE',
      payload: { wawelcometandalone: "Oi, em que podemos ajudar?" } // JSON.stringify(resp.data.telefones)
    }); */
  }

  const goDirect = () => {
    Api.get(`/slug/golink`).then(resp => {
      setStatus(resp.data.status);
    }).finally(() => {
      dispatch({
        type: 'SET_LOADING',
        payload: { loading: false } // JSON.stringify(resp.data.enderecos)
      });
    })
  }

  useEffect(() => {
    let mounted = true;

    Api.get(`/slug/${tenant}`).then(resp => {

      if(mounted){
        if(resp.data.status == 200) {
  
          localStorage.removeItem('persist:data');
          setStatus(resp.data.status);
          setStore(resp.data);
  
        } else  {

          setStatus(resp.data.status);
          goDirect();
          
        }
      }

    }).finally(() => {
      dispatch({
        type: 'SET_LOADING',
        payload: { loading: false } // JSON.stringify(resp.data.enderecos)
      });
    }).catch(error => {
      /* console.log(error); */
      dispatch({
        type: 'SET_LOADING',
        payload: { loading: true } // JSON.stringify(resp.data.enderecos)
      });
    })

    dispatch({
      type: 'SET_LOADING',
      payload: { loading: true } // JSON.stringify(resp.data.enderecos)
    });

  }, [status]);

  if(status === 200) {
    return <Redirect to={`/${tenant}/home`}/>; 

  } else if(status === 417)  {
    return <Redirect to={"/golink/home" }/>;
    
  } else {
    return (0);
  }
  
}

export default Slug;