import React/* , {useState, useEffect, useLayoutEffect} */ from 'react'; //
import { useSelector, useDispatch } from 'react-redux';

import Routes from './routes';
import Header from './components/header';
import Footer from './components/footer';

import Api from './components/Api'

const App = () => {

  localStorage.clear();

  const dispatch = useDispatch();

  const { tenant, configs } = useSelector(state => state.tenant);
  const { loading, wawelcometandalone } = useSelector(state => state.tenant);

  if(typeof tenant.status === "undefined" && typeof tenant.slug === "undefined") {
    let url = window.location.href;
    let urlexp = url.split("/");

    console.log(urlexp[3]);
    console.log(tenant.status, tenant.slug, window.location.href);

    Api.get(`/slug/${urlexp[3]}`).then(resp => {

        if(resp.data.status == 200) {
  
          
          dispatch({
            type: 'SET_TENANT_CONFIG',
            payload: { configs: resp.data.configs }
          });
          dispatch({
            type: 'SET_TENANT_ENDERECOS',
            payload: { enderecos: resp.data.enderecos } // JSON.stringify(resp.data.enderecos)
          });
          dispatch({
            type: 'SET_TENANT_TELEFONES',
            payload: { telefones: resp.data.telefones } // JSON.stringify(resp.data.telefones)
          });
      
          delete resp.data.enderecos;
          delete resp.data.telefones;
          delete resp.data.configs;
      
          dispatch({
            type: 'SET_TENANT',
            payload: { tenant: resp.data } // JSON.stringify(resp.data.telefones)
          });
  
        }
      

    }).finally(() => {
      dispatch({
        type: 'SET_LOADING',
        payload: { loading: false } // JSON.stringify(resp.data.enderecos)
      });
    }).catch(error => {
      /* console.log(error); */
      dispatch({
        type: 'SET_LOADING',
        payload: { loading: true } // JSON.stringify(resp.data.enderecos)
      });
    })


  }

  

  document.title = `${tenant.nome} ~ [ GO! Link ]`;

  const tenantContainerApp = {
    backgroundImage: configs.background,
    color: configs.colorTxt2,
  };

  /* console.log(configs.background); */
 
  

  return (
      
      <section style={tenantContainerApp} className="App">

        { loading ? <div className="logoLoading">loading...</div> : null }
        

        <Header></Header>

        <article className="content">
          
          <Routes />

        </article>

        <Footer></Footer>

      </section>
  );
}

export default App;