import React, {useState} from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const whatsappMultiple = (props) => {
    const {tenant, configs, telefones, enderecos} = props;

    const telWa = telefones.map(function(wa, k){
        wa.numero = wa.numero.replace('-','');
        const waComplet = `${wa.ddi}${wa.ddd}${wa.numero}`;

        //console.log(wa.isWhatsapp);

        return (
            <a key={k} href={`https://api.whatsapp.com/send?phone=${waComplet}`}>
                <li>
                    <div><img src={`./../assets/imgs/${wa.whatsappFoto}`} alt="" className="WaMtpleFoto" /></div>
                    <div><p className="WaMtpleNome">{wa.whatsappNome}</p></div>
                    <div><FaWhatsapp size='30' className='WaMtpleIcon' /></div>
                </li>
            </a>
        );
    })

    return (
        <div className="WaMtple">
            <div className="Header">
                <div><FaWhatsapp size='50'  /></div>
                <div><p>{configs.waMtpleHeaderTitle}</p></div>
            </div>
            <div className="Body">
                <ul>{telWa}</ul>
            </div>
        </div>
    );

};

export default whatsappMultiple;