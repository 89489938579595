import React/* , {useState, useEffect, useLayoutEffect} */ from 'react'; 
import { useSelector } from 'react-redux';

const Header = () => {

  const { tenant, configs } = useSelector(state => state.tenant);

  return (
    <header className="header">
      <img src={configs.logo} alt={tenant.nome} title={tenant.nome} className="headerLogo"/>
    </header>
  );
}

export default Header;