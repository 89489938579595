import React/* , {useState, useEffect} */ from 'react';
import { useSelector } from 'react-redux';

const Telefone = ({match}) => {

  const { tenant/* , configs, telefones, enderecos */ } = useSelector(state => state.tenant);   

  return (
    <>
      <p>{tenant.nome} /telefone</p>
    </>
  );
}

export default Telefone;


