import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { MdSend } from 'react-icons/md';

const whatsappStandalone = (props) => {

    const {tenant, configs, telefone, enderecos, wainputstandalone, setWainputstandalone, wawelcometandalone, SET_WAINPUTSTANDALONE} = props;

    /* console.log(wainputstandalone); */
    
    telefone.numero = telefone.numero.replace('-','');
    const waComplet = `${telefone.ddi}${telefone.ddd}${telefone.numero}`;

    //console.log(wa.isWhatsapp);

    return (
        <div className="WaStalo">
            <div className="Header">
                <div><img src={`./../assets/imgs/${telefone.whatsappFoto}`} alt="" className="WaStaloFoto" /></div>
                <div><p className="WaMtpleNome">{telefone.whatsappNome}</p></div>
                <div><FaWhatsapp size='34' className='WaSendStaloIcon' /></div>
            </div>
            <div className="Body">
                <div className="BodyOla">
                    <p>{configs.waStaloMsgReceived}</p>
                </div>
                <div className="BodyInput">
                    <div>
                        <input type="text" value={wainputstandalone} onChange={ (e) =>  setWainputstandalone(e.target.value) } /> {/*  SET_WAINPUTSTANDALONE(e.target.value */}
                    </div>
                    <div>
                        <a href={`https://api.whatsapp.com/send?phone=${waComplet}&text=${wainputstandalone}`}>
                            <MdSend size='26' className='WaStaloIcon' color='#00e676'  />
                        </a>
                    </div>
                </div>
                
            </div>
        </div>
    );

};

export default whatsappStandalone;