import { createStore } from 'redux';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import Reducers from '../store';

const pConfig = {
    key: 'data',
    storage,
    whitelist: ['tenant']
};

const pReducer = persistReducer(pConfig, Reducers);

const store = createStore(pReducer);
const persistor = persistStore(store);

export { store, persistor };