import React from 'react';
import { useSelector } from 'react-redux';

const Localizacao = () => {

  const { tenant/* , configs, telefones, enderecos */ } = useSelector(state => state.tenant);   

  return (
    <>
      <p>{tenant.nome} /localização</p>
    </>
  );
}

export default Localizacao;