import React/* , {useState, useEffect} */ from 'react';
import { useSelector } from 'react-redux';


const Home = () => {

  const { tenant/* , configs, telefones, enderecos */ } = useSelector(state => state.tenant); 

  return (
    <>          
      <p>{tenant.nome} /home </p>
    </>
  );
}

export default Home;


