import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import WhatsappMultiple from '../components/pgwhatsappMultiple';
import WhatsappStandalone from '../components/pgwhatsappStandalone';

const WhatsApp = () => {

  const { tenant, configs, telefones, enderecos, wawelcometandalone } = useSelector(state => state.tenant);

  const [wainputstandalone, setWainputstandalone] = useState(configs.waStaloMsgSend);


    const dispatch = useDispatch();
    const SET_WAINPUTSTANDALONE = (msg) => {
      /* console.log(msg); */
      setWainputstandalone(msg);
      /* dispatch({
        type: 'SET_TENANT_CONFIG',
        payload: { configs: msg } // JSON.stringify(resp.data.configs)
      }); */
    }
    


  /* useEffect(() => {
    setTssss(tenant.nome);

  }, []); */

  //console.log(wawelcometandalone);

  const telefonesFilter = telefones.filter((tel) => {
    return tel.isWhatsapp == 1 && tel.isActive == '1';
  });

  /* console.log(telefonesFilter.length); */

  return (
    
    <div className="whatsappContainer">

      {telefonesFilter.length === 1 ? (

        <WhatsappStandalone tenant={tenant} configs={configs} telefone={telefonesFilter[0]} enderecos={enderecos} wawelcometandalone={wawelcometandalone} wainputstandalone={wainputstandalone} setWainputstandalone={setWainputstandalone} SET_WAINPUTSTANDALONE={SET_WAINPUTSTANDALONE}/>  

      ) : telefonesFilter.length > 1 ? (

        <WhatsappMultiple tenant={tenant} configs={configs} telefones={telefonesFilter} enderecos={enderecos} />        

      ) : (

        <></>

      )}

    </div>
  );
}

export default WhatsApp;