import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Slug from './Slug';
import Home from './pages/Home';
import WhatsApp from './pages/WhatsApp';
import Localizacao from './pages/Localizacao';
import Telefone from './pages/Telefone';



export default function Routes() {
    return (
        <Switch>
            <Route exact path="/" >
                <Redirect to="/golink/home" />
            </Route>

            <Route exact path="/:tenant" component={Slug} />

            <Route path="/:tenant/home" component={Home} />
            <Route path="/:tenant/whatsapp" component={WhatsApp} />

            <Route path="/:tenant/telefone" component={Telefone} />
            <Route path="/:tenant/localizacao" component={Localizacao} />
        </Switch> 
    );
}