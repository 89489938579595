import React, {useState, useEffect} from 'react'; //
import { useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { FaMapMarkerAlt, FaPhone, FaHome, FaWhatsapp, FaInstagram } from 'react-icons/fa';

const Footer = () => {

  const { tenant, telefones/* , enderecos, configs */ } = useSelector(state => state.tenant); 

  /* console.log(telefones[0].numero); */

  const [whatsapp, setWhatsapp] = useState(0);
  const [referrer] = useState(window.document.referrer);
  
  useEffect(() => {
  
    const wa = telefones.filter((tel) => {
      return tel.isWhatsapp == 1 && tel.isDefault == '1' && tel.isActive == '1';
    });

    /* console.log(wa, telefones); */
        
    wa[0].numero = wa[0].numero.replace('-','');

    /* console.log(`${wa[0].ddi}${wa[0].ddd}${wa[0].numero}`); */
        
    setWhatsapp(`${wa[0].ddi}${wa[0].ddd}${wa[0].numero}`);
    
  }, []);

  return (
    <nav className="nav">
        <NavLink className="link" activeClassName='linkActive' to={`telefone`}>
            <FaPhone size='20' />
        </NavLink>

            {referrer === "https://l.instagram.com/" ? (
              <a href={`https://wa.me/${whatsapp}`} className="link">
                <FaWhatsapp size='20' className='icons' />
              </a>
            ) : (
              <a href={`https://instagram.com/${tenant.instagram}`} alt={tenant.instagram} title={tenant.instagram} className="link">
                <FaInstagram size='20' className='icons' />
              </a>
            )}

        <NavLink className="link home" activeClassName='linkActive' to={`home`}>
            <FaHome size='20' />
        </NavLink>

        <NavLink className="link" activeClassName='linkActive' to={`whatsapp`}>
            <FaWhatsapp size='20' className='icons' />
        </NavLink>

        <NavLink className="link" activeClassName='linkActive' to={`localizacao`} >
            <FaMapMarkerAlt size='20' />
        </NavLink>
    </nav>
  );
}

export default Footer;