const initialState = {
    loading: true,
    tenant: {
        nome: '',
    },
    configs: {
        background:'',
        colorTxt2:'',
        logo: '',
    },
    telefones: [{
        numero: '55(00)00000-0000',
        isWhatsapp: 1,
        isDefault: 1,
        isActive: 1,
    }],
    wawelcometandalone: 'Oi, em que podemos ajudar?'
};

const tenantReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'SET_TENANT':
            return {...state, tenant: action.payload.tenant};
        break;
        case 'SET_TENANT_CONFIG':
            return {...state, configs: action.payload.configs};
        break;
        case 'SET_TENANT_ENDERECOS':
            return {...state, enderecos: action.payload.enderecos};
        break;
        case 'SET_TENANT_TELEFONES':
            return {...state, telefones: action.payload.telefones};
        case 'SET_LOADING':
            return {...state, loading: action.payload.loading};
        break;
        case 'SET_WAWELCOMETANDALONE':
            return {...state, wawelcometandalone: action.payload.wawelcometandalone};
        break;
        //default: return state;
    }

    return state;
}

export default tenantReducer;